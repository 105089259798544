<template>
  <v-card color="blue-grey lighten-5">
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="8" md="6">
          <h3 class="mt-6 mb-1">Основні дані</h3>

          <v-avatar v-if="client.avatar" class="mb-3" size="100">
            <img :src="`${baseURL}/${client.avatar}`" alt="logo" />
          </v-avatar>

          <div>
            <span>ID:</span>
            <span class="font-weight-medium ml-2">{{ client.user_id }}</span>
          </div>

          <div>
            <span>Імʼя:</span>
            <span class="font-weight-medium ml-2">{{ client.first_name }}</span>
          </div>

          <div>
            <span>Прізвище:</span>
            <span class="font-weight-medium ml-2">{{ client.last_name }}</span>
          </div>

          <div>
            <span>Телефон:</span>
            <SpoilerElement class="ml-2" spoiler-title="Показати номер">
              <span class="font-weight-medium ml-2">{{ client.phone && formatPhoneNumber(client.phone) }}</span>
            </SpoilerElement>
          </div>

          <div>
            <span>Telegram:</span>
            <SpoilerElement v-if="clientsTelegram" class="ml-2" spoiler-title="Показати номер">
              <span class="font-weight-medium ml-2">{{ clientsTelegram && formatPhoneNumber(clientsTelegram) }}</span>
            </SpoilerElement>
          </div>

          <div>
            <span>Viber:</span>
            <SpoilerElement v-if="clientsViber" class="ml-2" spoiler-title="Показати номер">
              <span class="font-weight-medium ml-2">{{ clientsViber && formatPhoneNumber(clientsViber) }}</span>
            </SpoilerElement>
          </div>

          <div>
            <span>WhatsApp:</span>
            <SpoilerElement v-if="clientsWhatsApp" class="ml-2" spoiler-title="Показати номер">
              <span class="font-weight-medium ml-2">{{ clientsWhatsApp && formatPhoneNumber(clientsWhatsApp) }}</span>
            </SpoilerElement>
          </div>

          <div>
            <span>Дата народження:</span>
            <span class="font-weight-medium ml-2">{{ client.birthday | getShortDate }}</span>
          </div>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <PurchaseTable :purchase-data="clientsPurchase" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import SpoilerElement from '@/components/common/SpoilerElement'
import PurchaseTable from '@/components/support/users/PurchaseTable'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'
import convertDate from '@/mixins/convertDate'

export default {
  name: 'GeneralInfo',

  components: { PurchaseTable, SpoilerElement },

  mixins: [convertDate],

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API_URL,
      clientsPurchase: [],
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    client(val) {
      this.setPurchaseData(val)
    },
  },

  computed: {
    clientsTelegram() {
      return this.client.contacts?.personal
        ? this.client.contacts.personal[0]?.contact_type?.find((item) => item.type === 'telegram_channel')?.value || null
        : null
    },

    clientsViber() {
      return this.client.contacts?.personal
        ? this.client.contacts.personal[0]?.contact_type?.find((item) => item.type === 'viber')?.value || null
        : null
    },

    clientsWhatsApp() {
      return this.client.contacts?.personal
        ? this.client.contacts.personal[0]?.contact_type?.find((item) => item.type === 'whatsapp')?.value || null
        : null
    },
  },

  methods: {
    formatPhoneNumber,

    setPurchaseData(val) {
      this.clientsPurchase = [
        {
          companyId: null,
          is_active_client: val.is_active_client,
          last_payment_date: val.last_payment_date,
          payment_count: val.payment_count,
          payment_amount: val.payment_amount,
        },
      ]
    },

    initialize() {
      this.setPurchaseData(this.client)
    },
  },
}
</script>
