<template>
  <div class="spoiler-wrapper d-inline">
    <slot></slot>
    <div v-if="!isSpoilerOpened" class="spoiler" @click="isSpoilerOpened = true">{{ spoilerTitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'SpoilerElement',

  props: {
    spoilerTitle: {
      type: String,
      default: 'Показати',
    },
  },

  data() {
    return {
      isSpoilerOpened: false,
    }
  },
}
</script>

<style scoped lang="scss">
.spoiler {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #aaaaaa;
  border-radius: 4px;
  cursor: pointer;
  &-wrapper {
    position: relative;
  }
}
</style>
